/* general */
.line {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
    display:block;
    width:100%;
}
img {
    max-width:100%;
}
h1, h2, h3, h4, h5, h6 {
    font-weight:bold;
    color:var(--ion-color-primary, #1d3d51);
}
.formError {
    display:block;
}
.item {
    cursor:pointer;
}
.item.ion-activatable:hover {
    --background-hover: #403f3f;
    font-weight:bold;
    cursor:auto;
}
.defaultInput {
    background-color:#eee;
    margin-bottom:15px;
    --padding-start:15px;
    --padding-end:15px;
    --padding-bottom:10px;
    --padding-top:10px;
}
.defaultRadio .item {
    --ion-item-background:#eee;
    --border-width:0;
}
.defaultRadio .item {
    margin-top:5px;
}
.sc-ion-segment-md-h {
    --color: var(--ion-color-secondary);
}
.item .removeNoWrap {
    white-space:initial;
}
.mainBackground {
    --background:none;
    background-size: cover;
}
.toastMessage {
    --background:#fff;
    --button-color:var(--ion-color-primary, #1d3d51);
    --ion-color-step-100: #000;
    color:#000;
}

.toastMessage.toastMessageDanger {
    --color: var(--ion-color-danger, red);
    color:var(--color);
}
.profileButton {
    white-space:normal;
    height:auto;
    --padding-top: 10px;
    --padding-bottom:10px;
    --border-width: 2px;
    --border-style: solid;
    border-left:2px solid;
    border-right:2px solid;
}
ion-list-header.ios {
    margin-bottom:16px;
}

.linkButton {
    text-decoration: underline;
    letter-spacing: inherit;
    text-transform: inherit;
    font-size:inherit;
    white-space:inherit;
    --background-focused: none;
    --background-hover: none;
    --transition:none;
    --ripple-color:transparent;
    --background-activated:none;
    --border-radius: none;
    transition:none;
    margin:0;
    border:none;

}

.linkButton:focus {
    border:none;
    outline:none;
}

@media (max-width:767px){
    .profileButton {
        max-width:100%;
    }
}
@media (min-width:768px) {
    .mainToolbar {
        border-left: 2px solid var(--ion-color-primary-tint);
    }

    .mainToolbar .button:hover {
        --background-hover-opacity: 0.2;
    }
}

.placeholderImage {
    background-color:#e5e2e2;
    padding:30px;
    font-size:100px;
    text-align:center;
}
/*home*/
.companyLogoHome {
    margin:auto;
    display:block;
    max-width:300px;
    padding:15px;
}

@media (min-width:768px){
    .companyLogoHome {
        margin-top:30px;
    }
}
.homeIcon {
    text-decoration:none;
    font-size:18px;
}
.homeIcon i {
   font-size:40px;
}
.homeIconRow {
    margin-top:30px;
}
.homeIconsWrapper {
    bottom:15px;
    position:absolute;
    left:0;
    right:0;
}
@media (orientation: landscape) and (max-width:768px) {
    .homeIconsWrapper {
        position:inherit;
    }
    .homeIconRow {
        margin-top:10px;
    }
}

@media (min-width:768px){
    .homeIcon:hover {
        color:var(--ion-color-primary);
        text-decoration: underline;
    }
}

.secondaryLinkColor a {
    color:var(--ion-color-secondary, #ffffff);
}
/*menu */
.companyLogo {
    max-width:200px;
    padding:15px;
}
.mainMenuWrapper {
    border:none;
}
.mainMenu {
    background:var(--ion-color-primary, #1d3d51);
}

.mainMenu .item:hover {
    --background-hover-opacity:0.2;
    font-weight:normal;
}
.menuItem {
    font-size:18px;
}
/* login, register, password reset */
.authenticateBackground {
    --background:none;
    background-size: cover;
}
.authenticateWrapper {
    background:#fff;
    padding:30px;
    max-width:400px;
    margin:auto;
}
.logoImage {
    max-width:400px;
    margin:auto;
    margin-bottom:50px;
    width:100%;
}

.defaultButton {
    --background:#1d3d51;
    --background-activated:#0c2637;
    --background-focused:#0c2637;
    --background-hover:#0c2637;
    text-transform:unset;
}
a.defaultLink {
    color:#1d3d51;
    text-decoration:none;
}
.ion-color-facebook {
    background-color:#4267b2;
}
.ion-color-facebook:hover {
    background-color:#2c4d92;
}
.facebookButton {
    text-transform:unset;
}
.facebookButton i {
    margin-right:10px;
}
.googleButton {
    text-transform:unset;
}
.googleButton i {
    margin-right:10px;
}
.ion-color-google {
    background-color:#DB4437;
}
.ion-color-google:hover {
    background-color:#c13e33;
}

@media (max-width:500px){
    .facebookButton, .googleButton {
        width:100%;
    }
    .authenticateWrapper {
        min-height:100%;
    }

}
/* settings */
.settingsWrapper {
    background:#fff;
}
.settingsPage .defaultInput {
    margin-top:5px;
}
.qrCode {
    position:absolute;
    display:block;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
@media (min-width:768px) {
    .settingsPage .settingsSubmit .button {
        max-width: 200px;
        float: right;
    }
}
/* match overview */
.cardBackground {
    --background:#fff;
}
.md.segmentButton {
    font-weight:bold;
    --color: var(--ion-color-secondary);
}
.ios.segmentButton {
    --indicator-color:var(--ion-color-primary);
    --color-checked:var(--ion-color-primary-contrast);
    --color: var(--ion-color-secondary);
}

.matchCardLink {
    text-decoration:none;
    color:#000;
}
.matchResults p {
    font-size:20px;
    color:#000;
    font-weight:bold;
}
.predictionInput .native-input[disabled] {
    opacity:1;
}
.predictionButton {
    height:40px;
    width:40px;
    font-size:16px;
    font-weight:bold;
}
.predictionSaveButton {
    text-transform:uppercase;
    letter-spacing:0.06em;
    font-size:14px;
    --border-radius:5px;
}
.predictionMessage {
    font-size:12px;
    margin-top:15px;
}
.liveBadge {
    margin-left:5px;
}
.ios.segment-button-checked .liveBadge {
    background:var(--ion-color-tertiary, #1d3d51);
    color:var(--ion-color-primary, #ffffff);
    --padding-end: 5px;
    --padding-start: 5px;
    --padding-bottom: 1px;
    --padding-top: 1px;
}
.matchesOverviewDateHeader {
    margin-bottom:10px;
    display:block;
}

.upcomingMatchTeamImage {
    height: 60px;
    min-height: 60px;
}

@media (min-width:992px){
    .upcomingMatchTeamImage {
        height: auto;
    }
}
/* matches */
.matchTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right:5px;
    font-size:16px;
    font-weight:normal;
}
.competitionLogo {
    max-width:150px;
    margin:auto;
    display:block;
}
.teamLogo {
    max-width:100px;
    margin:auto;
    display:block;
}
.teamLogoMatch {
    width:100%;
    max-width:100px;
    margin:auto;
    display:block;
}
.teamSlide .teamLogo {
    margin-top:15px;
    display:block;
    max-width:150px;
}
.teamSlide .IonLabelSmallerFontSize {
    font-size:14px;
}

.matchScore {
    font-size:36px;
    margin:auto;
}
.matchTimeElapsed {
    font-size:18px;
    margin:auto;
}
.cardRed {
    color:#ff0000;
}
.cardYellow {
    color:#ffdb00;
}
.cardYellowRed {
    color:#ffdb00;
}
.cardYellowRed:after {
    content: "\f2fb";
    font-family: "Font Awesome 5 Pro";
    color:red;
    position:absolute;
    left:8px;
    top:5px;
}
.teamName {
    text-align:center;
}
.teamName a {
    text-decoration:none;
}
.teamEliminated {
    text-decoration: line-through;
}
.previousOutcome {
    text-align:center;
}
@media (max-width:767px){
    .previousOutcome:not(.teamPage):first-child {
        text-align:left;
    }
    .previousOutcome:not(.teamPage):last-child {
        text-align:right;
    }
}
.previousOutcomeBadge {
    height:18px;
    width:18px;
    font-size:10px;
    margin-right:5px;
    padding:0;
    text-align:center;
    line-height: 18px;
    vertical-align: middle;
}
.teamPage .previousOutcomeBadge {
    height:25px;
    width:25px;
    font-size:14px;
    line-height:25px;
}
.previousOutcomeBadge.winColor {
    background:green;
}
.previousOutcomeBadge.drawColor {
    background:orange;
}
.previousOutcomeBadge.loseColor {
    background:red;
}
.regEvent {
    font-size:80%;
}
.eventRow {
    font-size:14px;
}
.substitutionEventOut {
    color:red;
}
.substitutionEventIn {
    color:green;
}

.matchInfoSlider .swiper-slide {
    padding-top:30px;
}
.swiper-pagination-bullet{
    --bullet-background:var(--ion-color-primary-tint);
}
.matchInfoSlider .swiper-pagination {
    top:0;
    bottom:inherit;
}
.playerNumber {
    font-weight:bold;
    font-size:14px;
}
.item .playerLabel {
    white-space:normal;
}
.playerName {
    width:100%;
}
.matchStatisticsRow {
    padding:5px;
}
.evenRow {
    background-color:#eee;
}
.shareButtonIcon {
    padding-right:14px;
    margin-right:10px;
    line-height:36px;
}
@media (max-width:767px){
    .teamName:first-child {
        text-align:left;
    }
    .teamName:last-child {
        text-align:right;
    }
    .teamName .title-default {
        padding:0;
    }
}
@media (max-width:400px){
    .teamName .title-default {
        font-size:14px;
    }
}

.teamPageLogoContainer {
    max-width:300px;
    margin:auto;
}
/* player statistics */
.cardLayout {
    -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
/* Rankings */
.rankingTitles i {
    color:var(--ion-color-primary, #1d3d51);
    font-size:18px;
}
.rankingTitles {
    font-weight:bold;
    margin-bottom:5px;
}
.rankingPoints {
    max-width:100px;
}
.activeRankingRow {
    font-weight: bold;
    color:var(--ion-color-primary, #1d3d51);
}
ion-refresher {
    z-index:0;
}

.rankingFab {
    width:100%;
}
.rankingFabButton {
    width:100%;
    display:block;
    text-align:left;
    font-size:16px;
    margin:0;
}

.rankingFab .rankingFabBadge {
    --background:#fff;
    --color: var(--ion-color-primary);
    margin-right:10px;
    font-weight:bold;
}

    /* groups */

.itemEvenRow {
    --background:#eee;
}

.extraInfoSmallScreen {
    display:none;
    flex: 0 0 auto;
}

.groupStageHeaderTitle {
    flex:0 0 auto;
    width: 100%;
}

.groupStagePositionColumn {
    flex: 0 0 auto;
    width:15%;
}

.groupStageTitleColumn {
    flex:0 0 auto;
    width: 70%;
}

.groupStagePointsColumn {
    flex:0 0 auto;
    width: 15%;
}

.infoButtonLandscape {
    display:none;
}

.groupStageInfoModalAbbreviation {
    font-weight:bold;
    min-width:40px;
}

@media (orientation: landscape) and (min-width:568px){
    .extraInfoSmallScreen {
        display:block;
        width:calc(100% / 12);
    }

    .groupStageHeaderTitle {
        width: calc(30% + (100% / 12));
    }

    .groupStagePositionColumn {
        width:calc(100% / 12);
    }

    .groupStageTitleColumn {
        width: 30%;
    }

    .groupStagePointsColumn {
        width:calc(100% / 12);
    }

    .infoButtonLandscape {
        display:block;
    }
}
/* vouchers */
.voucherCard {
    --background:#fff;
    width:100%;
    height:100%;
    text-align:center;
}
.voucherUsed ion-card-header, .voucherUsed ion-card-content {
    opacity:0.4;
}
.voucherSlider {
    padding-bottom:25px;
}
.voucherSlider .swiper-pagination {
    bottom:0;
}
.voucherUsed .voucherCardTop {
    position:relative;
    min-height:100px;
}
.voucherUsed .voucherCardTop:after {
    background: #fff;
    opacity: 0.8;
    z-index: 10;
    position: absolute;
    content: "";
    top:0;
    left:0;
    right:0;
    bottom:4px;
    background-image: linear-gradient(45deg,rgba(0,0,0,.075) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.075) 50%,rgba(0,0,0,.075) 75%,transparent 75%,transparent);
    background-size: 40px 40px;
}
.voucherUsed .voucherUsedText {
    z-index:11;
    position:absolute;
    top:50%;
    left:50%;
    width:100%;
    text-align:center;
    transform:translate(-50%, -50%);
    display:block;
    font-size:20px;
    color:var(--ion-color-primary,#1d3d51);
    font-weight:bold;
}
.voucherActivate {
    max-width:200px;
    margin-left:auto;
    margin-right:auto;
    height:auto;
    --padding-top: 10px;
    --padding-bottom:10px;
    --border-width: 2px;
    --border-style: solid;
    border-left:2px solid;
    border-right:2px solid;
}
.voucherUsed .voucherActivate, .voucherDisabled .voucherActivate {
    display:none;

}

.countDown {
    position:relative;
}
.countDownNumber {
    color:#fff;
    font-size:20px;
    margin-bottom:20px;
    position:absolute;
    z-index:1;
    left:50%;
    transform:translateX(-50%);
}
.countDownBarContainer {
    display:flex;
    align-items:center;
    margin-bottom:15px;
    background:#cccccc;
}
.countDownBar {
    background:var(--ion-color-primary,#1d3d51);
    height: 30px;
    line-height: 30px;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.075);
    transition: width 1s linear;
    transform: translate3d(0, 0, 0);
    background-image: linear-gradient(45deg,rgba(255,255,255,.045) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.045) 50%,rgba(255,255,255,.045) 75%,transparent 75%,transparent);
    background-size: 40px 40px;
    animation: progress-bar-stripes 2s linear infinite;
}
@-webkit-keyframes progress-bar-stripes{from{background-position:-40px 0}to{background-position:0 0}}
@-o-keyframes progress-bar-stripes{from{background-position:-40px 0}to{background-position:0 0}}
@keyframes progress-bar-stripes{from{background-position:-40px 0}to{background-position:0 0}}

@media (orientation: landscape) {
    hideLandscape {
        display:none;
    }
}
/* Statistics team */
.statisicBar {
    background-color:#eee;
}
.statisicBar .homeBarPart {
    background-color: var(--ion-color-primary);
    float:left;
    height:10px;
}
.statisicBar .awayBarPart {
    background-color: var(--ion-color-primary);
    filter: brightness(220%);
    float:left;
    height:10px;
}

.statisicBar:after {
    content: "";
    clear: both;
    display: table;
}
/* about */
.aboutContent > *:first-child{
    margin-top:0;
}
.noBorderRadius {
    border-radius:0;
}

/* news */
.newsItemCard {
    position:relative;
    min-height:150px;
}
.newsItemCard ion-card-header {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    background:rgba(0,0,0,0.5)
}
.newsItemCard .cardImage {
    max-height:200px;
}
.newsItemCard ion-card-title, .newsItemSimple ion-card-title {
    font-weight:bold;
}
.newsItemCard ion-card-title, .newsItemCard ion-card-subtitle/*, .newsItemSimple ion-card-title, .newsItemSimple ion-card-subtitle*/ {
    color:#fff;
}
.newsItemSimple ion-thumbnail {
    float:left;
    margin-right:15px;
}

.newsItemSimple .newsItemTitle {
    max-height:3.9em;
    overflow:hidden;
    padding-right:10px;
    font-size:14px;
    line-height:1.3em;
}
.newsItemSimple .newsItemSubTitle {
    padding-right:10px;
    overflow:hidden;
    max-width: 100%;
    word-break:break-all;
    max-height:1.3em;

}
.newsItemSimple .newsItemThumbnail {
    vertical-align: middle;
    max-width:inherit;
    max-height:100%;
    position:absolute;
    left:50%;
    bottom:0;
    transform:translateX(-50%);
}
.newsItemSimple .newsItemThumbnailContainer .IonSpinner {
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
}
.newsItemSimple .newsItemThumbnailContainer {
    height:5.9em;
    width:120px;
    overflow:hidden;
    position:relative;
}
.newsItemPublishing {
    font-weight:bold;
    margin-top:0;
    font-size:12px;
}

@media (max-width:400px){
    .newsItemSimple .newsItemThumbnailContainer {
        width:90px;
    }
}

@media (min-width:768px){
    .newsItemSimple.cardBackground:hover {
        --background:#ddd;
    }
}
/* player */
.playerNameTitle {
    font-weight:bold;
    font-size:24px;
    margin-bottom:5px;
}
.playerNameTitle ion-col {
    padding-left:0;
}
.flagImage {
    max-width: 16px;
    display:inline-block;
    margin-right:5px;
    border:1px solid #eee;
}
.playerCard {
    font-size:16px;
}
.playerStatistic {
    font-size:16px;
}
.gameRulesModalContainer ion-text {
    padding: 12px 0;
    font-size:14px;
}

.gameRulesModalCloseButton {
    display:block;
}

/* scrollarrow */
.scrollArrow {
    position:fixed;
    bottom:16px;
    font-size:30px;
    color: var(--ion-color-primary);
    text-align:center;
    width:100%;
    opacity:1;
    animation: pointDown 1s infinite alternate;
    transition: 0.5s ease-in-out opacity, 0.5s ease-in-out visibility;
    pointer-events: none;
}

@keyframes pointDown {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(0, 10px);
    }
}

/* groups page */
ion-card.groupSlide {
    height: 450px;
    width: 100%;
}

.joinGroupShare, .joinGroupLink {
    position:absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.joinGroupLink {
    display:flex;
    width:80%;
    align-items:center;
}

.joinGroupLink .defaultInput {
    margin:0;
}

@media (max-width:400px){
    .joinGroupShare .button {
        font-size: 0.8em;
    }
}
